import React, { useState } from 'react'
import NewsListingBanner from "../NewsListingBanner/NewsListingBanner"
import _ from "lodash"
import { Container } from "react-bootstrap"
import { capitalFirst, customStylesNews } from "../Common/utils"
import Select from "react-select"
import usePagination from "../../hooks/usePagination"
import InnerPagination from "../InnerPagination/InnerPagination"
import { navigate } from "gatsby"

import "./NewsListing.scss"
import NewsCardList from "../NewsCardList/NewsCardList"
import { useEffect } from "react"
import { useLocation } from '@reach/router'
import Pagination from '../Pagination/Pagination'

const NewsListing1 = (props) => {

  const [isEventChange, setIsEventChange]=useState(false)

  const totalItems = props?.data?.length;
  const [totalCount, setTotalCount] = useState(totalItems)
  const location = useLocation()
  const itemsPerPage = isEventChange?13:12
  const pageSize = Math.ceil(totalCount/itemsPerPage)


  let allTabOption = {
    label: "All",
    value: "all",
  }

  let tabCategories=[];
  tabCategories.push(allTabOption)
  props.blogCategories?.forEach(element => {
    if (element.node?.blog_category) {
      tabCategories.push({
        label: element.node?.blog_category,
        value: element.node?.slug,
      })
    }
  })

  let alltopicOption = {
    label: "All Topics",
    value: "all-topics",
  }


  const sortedTopics=props.blogTopics?.sort((a,b)=> a.node?.topic_name.localeCompare(b.node?.topic_name))

  let topicCategories=[];
  topicCategories.push(alltopicOption);
  sortedTopics?.forEach(element => {
    if (element.node?.topic_name) {
      topicCategories.push({
        label: element.node?.topic_name,
        value: element.node?.slug,
      })
    }
  })

   //sorting in descending order with respect to date
   let blogData=props.data?.sort((a,b)=>{
    return new Date(b.node?.date) - new Date(a.node?.date)
  })
  //-------

  //getting query value from url
  let queryTabValue;
  if (typeof window !== 'undefined') {
    const result = new URLSearchParams(window.location?.search);
    const tabResult = result?.get("blog-category")
    queryTabValue = {
      label: capitalFirst(tabResult?.replace(/-/g, " ")),
      value: tabResult,
    }
  }


  let queryTopicValue;
  if (typeof window !== 'undefined') {
    const result = new URLSearchParams(window.location?.search);
    const topicResult = result?.get("blog-topic")
    queryTopicValue = {
      label: capitalFirst(topicResult?.replace(/-/g, " ")),
      value: topicResult,
    }
  }


  let queryTabData=""
  let quertyTopicData=""
  let queryData=""

  if(queryTabValue?.value!=="all"&&queryTopicValue?.value!=="all-topics"){
    const filtered1=_.filter(blogData, function (o) {
      if (o.node?.blog_categories?.length>0) {
        return o.node.blog_categories?.some(item=>item.slug===queryTabValue?.value);
     }
    })

    const filtered2=_.filter(filtered1, function (o) {
      if (o.node.blog_topics?.length>0) {
        return o.node.blog_topics?.some(item=>item.slug===queryTopicValue.value);
     }
    })
    queryData=filtered2
  }

  else if(queryTabValue?.value!=="all"&&queryTabValue?.value){
      const filtered=_.filter(blogData, function (o) {
        if (o.node?.blog_categories?.length>0) {
          return o.node.blog_categories?.some(item=>item.slug===queryTabValue?.value);
       }
      })
      queryTabData=filtered
     
  }

  else if(queryTopicValue?.value!=="all-topics"&&queryTopicValue?.value){
    const filtered = _.filter(blogData, function (o) {
      if (o.node.blog_topics?.length>0) {
        return o.node.blog_topics?.some(item=>item.slug===queryTopicValue?.value);
     }
    })
    quertyTopicData=filtered

  }

  //-----

  const [tabOptions,setTabOptions]=useState(tabCategories)
  const [topicOptions, setTopicOptions]=useState(topicCategories)

  let [filteredList, setFilteredList] = useState(queryTabData?.length>0?
    queryTabData:quertyTopicData?.length>0?quertyTopicData:queryData?.length>0?queryData:blogData)


  const [tabValue, setTabValue]=useState(queryTabValue?.value?queryTabValue:allTabOption)

  const [topicValue, setTopicValue]=useState(queryTopicValue?.value?queryTopicValue:alltopicOption)

  useEffect(() => {
    const splitUrl = location.pathname.split('/')
    const getPagePath = splitUrl?.length>2 ? splitUrl[3] :''
    const pageFromUrl = parseInt(getPagePath.replace('page-', '') || 1);
    setCurrentPage(pageFromUrl);
  }, [location.pathname]);


    // for pagination
  //  const itemsPerPage = isEventChange?13:12
  const match = location.pathname.match(/page-(\d+)/);
  const currentPageFromUrl = match ? parseInt(match[1]) : 1;
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: filteredList,
    itemsPerPage,
    initialPage: currentPageFromUrl,
  })
  // for pagination


  const handleTabChange = (option)=>{
    setTabValue(option);

    let pathUrl = '/resources/latest-news-and-insights/'
    let path = [];
    if(option.value){
      path.push(`blog-category=${option?.value}`)
    }
    if(topicValue?.value){
      path.push(`blog-topic=${topicValue?.value}`)
    }
    
    const pathresult = path?.length>0 ? `${pathUrl}?${path.join('&')}`: pathUrl
    navigate(pathresult)
    setCurrentPage(1)
  }

  const handleTopics = (option)=>{
    setTopicValue(option);

    let pathUrl = '/resources/latest-news-and-insights/'
    let path = [];
    if(tabValue?.value){
      path.push(`blog-category=${tabValue?.value}`)
    }
    if(option?.value){
      path.push(`blog-topic=${option?.value}`)
    }
    
    const pathresult = path?.length>0 ? `${pathUrl}?${path.join('&')}`:''
    navigate(pathresult)
    setCurrentPage(1)
  }

  useEffect(() => {
    if (!tabValue || !topicValue) return;

    let filtered = blogData;

    if (tabValue.value !== "all" && topicValue.value === "all-topics") {
      filtered = blogData.filter(o =>
        o.node.blog_categories?.some(item => item.slug === tabValue.value)
      );
    } 
    else if (tabValue.value === "all" && topicValue.value !== "all-topics") {
      filtered = blogData.filter(o =>
        o.node.blog_topics?.some(item => item.slug === topicValue.value)
      );
    } 
    else if (tabValue.value !== "all" && topicValue.value !== "all-topics") {
      filtered = blogData.filter(o =>
        o.node.blog_categories?.some(item => item.slug === tabValue.value) &&
        o.node.blog_topics?.some(item => item.slug === topicValue.value)
      );
    }

    setIsEventChange(tabValue.value !== "all" || topicValue.value !== "all-topics");
    setFilteredList(filtered);
    setTotalCount(filtered.length)
  }, [tabValue, topicValue, blogData]); // Dependencies: Runs on change


  const newClass =(event,filter,queryTabValue)=>{

    let getAllCategoryId=document.getElementById("all-cat");
    if(queryTabValue?.value){
      if(queryTabValue?.value==="all"){
        getAllCategoryId?.classList.add("active")
      }
      else{
      const getElement=document.getElementsByClassName(queryTabValue?.value)
        const el=getElement[0]
        el&&el.classList?.add("active")
        getAllCategoryId?.classList.remove("active")
        setIsEventChange(true)
      }
    }
    else{
    var allChildElements = document.querySelectorAll(".nav-link-item")
    const getAllCategoryId=document.getElementById("all-cat");
    for (const box of allChildElements) {
      box.classList.remove("active")
    }
    event?.target?.classList?.add("active")
    if(!event){
      getAllCategoryId?.classList.add("active")
    }
    if(filter?.value){
      getAllCategoryId?.classList.remove("active")
    }
  }

  }

  useEffect(()=>{
    newClass("","",queryTabValue)
  },[])

  currentItems?.sort((a,b)=>{
    return new Date(b.node?.date) - new Date(a.node?.date)
   })

  const dynamicItem=currentItems?.length>0&&currentItems[0]?.node


  return (
    <>
    <NewsListingBanner 
    isPreviewEnabled = {props.isPreviewEnabled}
    newsData={isEventChange?dynamicItem:props.newsData} 
    strapi_id={isEventChange?dynamicItem?.strapi_id:props?.strapi_id}
    />
    <div className="header_selector-news">
      <Container>
        <div className="news-top-sections">
          <div className="categories-container">
            <div className="categories-list">
              <Select
                options={tabOptions}
                isSearchable={false}
                onChange={
                  handleTabChange
                 }
                placeholder={"All Categories"}
                className={"select-control"}
                classNamePrefix={"react-select"}
                // defaultInputValue={queryValue}
                defaultValue={queryTabValue?.value?queryTabValue:allTabOption}
                styles={customStylesNews}
                components={{
                  DropdownIndicator: () => (
                    <i className="icon icon-select-dropdown-dark"></i>
                  ),
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <ul class="inner-tab nav-tabs" id="filter-link">
            <li class="nav-item">
                  <button
                    type="button"
                   id="all-cat"
                    class="nav-link nav-link-item active tab_fill"
                    onClick={(event)=>{
                      newClass(event,"")
                      handleTabChange(allTabOption)
                    }}
                  >
                    All
                  </button>
                </li>
              {tabOptions?.slice(1, 20).map((filter, index) => (
                <li class={`nav-item`}>
                  <button
                    type="button"
                    // id={queryValue?queryValue:""}
                    class={`nav-link nav-link-item ${filter.value}`}
                    onClick={(event)=>{
                      handleTabChange(filter)
                      newClass(event,filter)}}
                  >
                    {filter.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="topics-container">
            <Select
              options={topicOptions}
              isSearchable={false}
              onChange={handleTopics}
              placeholder={"All Topics"}
              className={"select-control"}
              classNamePrefix={"react-select"}
              styles={customStylesNews}
              defaultValue={queryTopicValue?.value?queryTopicValue:alltopicOption}
              components={{
                DropdownIndicator: () => (
                  <i className="icon icon-select-dropdown-dark"></i>
                ),
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  
  <div className="news-listing-wrapper">
    {currentItems?.length > 1 && (
      <NewsCardList
        newsData={currentItems.slice(isEventChange?1:0, currentItems?.length)}
        strapi_id={props.strapi_id}
        subscriptionData={props.subscriptionData}
        newsValue={tabValue}
      />
    )}

    <Container>
      <Pagination
        pageSize={pageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        querySelectedOption={queryTabValue}
        querySelectedTopic = {queryTopicValue}
        pagepath = 'news'
      />
    </Container>
  </div>
  </>
  )
}

export default NewsListing1